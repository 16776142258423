import React from 'react'
  import { MDXTag } from '@mdx-js/tag'


  import DefaultLayout from "/buddy/openbook-github-io/workspaces/packages/gatsby-theme-andybrace/src/components/Layout/index.js";
import { Section, Heading } from 'gatsby-theme-andybrace';
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>


      <MDXTag name="h1" components={components}>{`About me`}</MDXTag>
      <MDXTag name="p" components={components}>{`I am a senior full stack developer with over 12 years experience delivering products in the education & NGO sectors. I’ve led cross functional development teams throughout the agile lifecycle for clients like British Council, Cancer Research, Save the Children & Public Health England.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Using my experience of rapid prototyping, iterative development and regular user feedback I am able to help clients embrace `}<a href="https://www.gov.uk/service-manual/agile-delivery">{`agile and lean principles`}</a>{` to build solutions that provide the most value to users.`}</MDXTag>
      <Section>
    <Heading tag='h2' className='subheading h5' text='Ways of working' />
    <Heading tag='h3' className='subheading h6' text='Scope' />
    <p>I conduct <a href="https://technology.blog.gov.uk/2016/02/08/the-role-of-an-architect-in-discovery/">technical discoveries</a> via interviews with technical stakeholders and desk research to identify a team's capabilities and constraints.</p> 
    <Heading tag='h3' className='subheading h6' text='Plan' />
    <p>Projects planned around <a href="https://www.gov.uk/service-manual/user-research/understanding-users-who-dont-use-digital-services">user personas and stories</a> maintain a better focus on whats really needed from a product.  I'm used to writing and maintaining these stories from project start to finish.</p>
    <Heading tag='h3' className='subheading h6' text='Prototype' />
    <p>I value sketching, prototyping and whiteboarding ideas before any code is written.  This helps to start conversations within a team and to arrive at a better solution.</p>
    <Heading tag='h3' className='subheading h6' text='Deliver' />
    <p>Quick delivery cycles means collaboration & code quality is important.  I'm happy <a target="_blank" href="https://gds.blog.gov.uk/2017/09/04/the-benefits-of-coding-in-the-open/">coding in the open</a>, and receiving & giving regular feedback.  I work with tools like Docker and AWS to allow for faster & more scalable deployments.</p>
      </Section>
      <Section>
    <Heading tag='h2' className='subheading h5' text='Tech radar' />
    <p>The <a href="https://www.thoughtworks.com/radar">Technology Radar</a> provided by <a href='https://www.thoughtworks.com'>Thoughtworks</a> is a great way to get the perspective of experts in the industry & is something I've been following to keep track of where the industry is moving.</p>
      </Section>
           </MDXTag>;
  }

}
MDXContent.isMDXComponent = true;
export const _frontmatter = {};
      